const downloadFile = (text, name, type) => {
  const anchor = document.createElement('a');
  anchor.style.display = 'block';
  const file = new Blob([text], { type: type });

  anchor.href = URL.createObjectURL(file);
  anchor.download = name;
  anchor.click();
};

export default downloadFile;
