import { parse } from 'json2csv';
import {
  filter,
  head,
  into,
  map,
  path,
  pipe,
  prop,
  propEq,
  reduce,
  sortBy,
} from 'ramda';
import datoLabelMap from '../../constants/datoLabelMap';

const blacklist = ['id', 'slug'];

const toCSV = data => {
  const nodes = pipe(path(['dato', 'edges']), map(prop('node')))(data);
  const rethinkNodes = pipe(
    path(['rethink', 'edges']),
    map(prop('node'))
  )(data);

  const transducer = map(({ internalId, ...rest }) => {
    const match = rethinkNodes.find(propEq('id', internalId));
    return {
      ...match,
      ...rest,
    };
  });

  const joinSources = pipe(into([], transducer), sortBy(prop('externalId')));

  const withTranslatedLabels = map(
    node =>
      reduce(
        (acc, key) => {
          acc[datoLabelMap[key] || key] = node[key]
            .replace(/\r?\n|\r/g, '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');

          return acc;
        },
        {},
        filter(key => !blacklist.includes(key), Object.keys(node))
      ),
    joinSources(nodes)
  );

  const fields = Object.keys(head(withTranslatedLabels));

  const opts = {
    fields,
    header: true,
    eol: '\r\n',
    delimiter: ';',
    withBOM: true,
  };
  return parse(withTranslatedLabels, opts);
};

export default toCSV;
