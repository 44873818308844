const labelMap = {
  name: 'Naam samenwerkingsverband',
  email: 'Email',
  startIntroText: 'Introductie met visie en missie',
  studentCountExplanation: 'Toelichting op aantal leerlingen',
  participationRateExplanation: 'Toelichting op deelnemers percentage',
  supportBudgetExplanation: 'Toelichting op budget',
  reservePositionExplanation: 'Toelichting op reservepositie',
  organizationForm: 'Organisatievorm',
  signalingValueExplanation: 'Toelichting op signaleringswaarde',
  executiveResponsibilities: 'Uitvoerende verantwoordelijkheden liggen bij',
  organizationFormExplanation: 'Toelichting op organisatievorm',
  supervisoryIndependence: 'Onafhankelijkheid in het intern toezicht',
  supervisoryDuties: 'Toezichthoudende taken liggen bij',
  supervisoryIndependenceExplanation:
    'Toelichting op onafhankelijkheid in het intern toezicht',
};

export default labelMap;
