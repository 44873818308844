import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Card from '@greenberry/salal/components/Card';
import Heading from '@greenberry/salal/components/Heading';
import Section from '@greenberry/salal/components/Section';
import theme from '@greenberry/salal/theme';
import Button from '@greenberry/salal/components/Button';
import Icon from '@greenberry/salal/components/Icon';

const Input = styled.input({
  width: '100%',
  padding: theme.spacing('s'),
  borderRadius: theme.get('borderRadius'),
  border: `1px solid ${theme.color('secondary.1')}`,
  outline: 'none',
  flex: 1,
  marginBottom: '1em',
});

const Label = styled.label``;
const Login = ({ onLogin }) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (value === 'povopo' && typeof window !== 'undefined') {
      window.localStorage.setItem('logged_in', 'true');
      setError(null);
      onLogin(true);
    }

    if (value !== 'povopo') {
      setError('Onbekend wachtwoord');
    }
  };

  return (
    <>
      <Section size="1">
        <Card>
          <Heading component="h1" size="xxxl" weight="semibold">
            Login
          </Heading>
          <form style={{ width: '100%' }} onSubmit={onSubmit}>
            <Label htmlFor="private_password">Password</Label>
            <Input
              id="private_password"
              type="password"
              onChange={e => setValue(e.target.value)}
              value={value}
            />
            <Button color="primary" small styling="solid" type="submit">
              <span>Login</span>
              <Icon size="lg" type="arrowRight" />
            </Button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </form>
        </Card>
      </Section>
    </>
  );
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default Login;
